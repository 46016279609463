import React from 'react';
import logo from './../assets/logo1.png';
import { AppBar, Tabs, Tab, Button, Grid, Toolbar, useTheme, useMediaQuery, Typography, Box, IconButton, Badge } from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import MiddleDrawer from './MiddleDrawer';
import { Routes, Route, Link, useLocation, Navigate } from 'react-router-dom';

export default function Header() {
    const theme = useTheme();

    const [value, setValue] = React.useState('/');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    //const smallContent = (9);
    const handleSignInClick = () => {
        // Handle sign-in click event
        console.log('Sign in clicked');
    };

    const bigContent = (
        <Grid container  >
            <Grid item xs={3} style={{ textAlign: 'center' }}>
                <Typography variant="body2" sx={{ mt: 2 }}> Our customer care</Typography>
                <Typography variant="subtitle1" sx={{ ml: 1 }}> 0172555345355</Typography>

            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>

                <img
                    src={logo}
                    alt={logo}
                    style={{ width: '225px', height: '80px' }}

                />

            </Grid>
            <Grid item xs={5} style={{ textAlign: 'right' }}>
                <Box
                    mt={10}
                    sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                    <Link to={'/signIn'}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, cursor: 'pointer' }} >
                        <AssignmentIndOutlinedIcon color="primary" sx={{ fontSize: 35 }} />
                        <Typography variant="subtitle1" sx={{ ml: 1 }}>signIn</Typography>
                    </Box>
                    </Link>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Badge badgeContent={4} color="primary">
                            <FavoriteBorderIcon color="primary" sx={{ fontSize: 35 }} />
                        </Badge>
                        <Typography variant="subtitle1" sx={{ ml: 1 }}>favorite</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Badge badgeContent={4} color="primary">
                            <ShoppingBagOutlinedIcon color="primary" sx={{ fontSize: 35 }} />
                        </Badge>
                        <Typography variant="subtitle1" sx={{ ml: 1 }}>Cart</Typography>
                    </Box>
                </Box>
            </Grid>

        </Grid>

    );

    const smallContent = (
        <Grid container  >
            <Grid item xs={3} mt={4} style={{ textAlign: 'center' }}>
                <MiddleDrawer/>

            </Grid>

            <Grid item xs={5} mt={5} style={{ textAlign: 'center' }}>

                <img
                    src={logo}
                    alt={logo}
                    style={{ width: '225px', height: '120px' }}

                />

            </Grid>

            {/* <Grid item xs={3} mt={3} style={{ textAlign: 'right' }}>

                <Typography variant="body2" sx={{ mt: 2 }}> Our customer care</Typography>
                <Typography variant="subtitle1" sx={{ ml: 1 }}> 0172555345355</Typography>

            </Grid> */}


        </Grid>
    )


    return (

        <>
            {smallScreen ? smallContent : bigContent}

        </>


    )
}


