import { Grid } from '@mui/material'
import React from 'react'
import logo from '../assets/logo.png'
import SignIn from '../features/auth/signIn'
import hena from '../assets/Hena.png'

const signInPage = () => {
    return (
        <div>
            <Grid container alignItems={'center'} justifyContent={'center'} style={{ textAlign: 'center',marginTop:'50px'}}>
                <Grid item  md={5} lg={6} sx={{ textAlign: 'center', display:{xs:'none',lg:'block'}  }}
                >
                    <img
                        src={logo}
                        alt={logo}
                        style={{ width: '275px', height: '250px', marginTop:'30px' }}
 
                    />
                </Grid>
                <Grid item sm={6} >
                    <SignIn />

                </Grid>
            </Grid>

        </div>
    )
}

export default signInPage
